import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import DigitalCollectiblesTab from "@/components/Home/DigitalCollectiblesTab";
import Expedition from "@/components/Home/Expedition";


const ExpeditionCarousel = () => {

  return (
    <div className="border-[1px] border-white">
      <Carousel
        autoPlay={true}
        dynamicHeight={true}
        infiniteLoop={true}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
      >
        <div>
          <DigitalCollectiblesTab />
        </div>

        <div>
          <Expedition/>
        </div>

      </Carousel>

    </div>


  );
}

export default ExpeditionCarousel;
