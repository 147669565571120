import axios from "axios";

type OwnershipResult = {
  isOwner: boolean;
  address?: string; // Assuming that the address is returned as well
};

const getNFTOwnership = async ({
  token,
  symbolKey,
}: {
  token?: string;
  symbolKey?: string | string[];
}): Promise<OwnershipResult> => {
  try {
    const params = new URLSearchParams();
    if (symbolKey) {
      if (Array.isArray(symbolKey)) {
        symbolKey.forEach(key => params.append("symbolKey", key));
      } else {
        params.append("symbolKey", symbolKey);
      }
    }

    const res = await axios.get("/api/user/verify-ownership", {
      headers: {
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0",
        "Content-Type": "application/json",
      },
      params,
    });
    return res.data;
  } catch (err) {
    console.error("Failed to fetch NFT status:", err);
    return { isOwner: false }; // Return a default value on error
  }
};

const getNFTOwnershipForMetamask = async ({
  address,
  symbolKey,
}: {
  address?: string;
  symbolKey?: string | string[];
}): Promise<OwnershipResult> => {
  try {
    const params = new URLSearchParams();
    if (address) params.append("address", address);
    if (symbolKey) {
      if (Array.isArray(symbolKey)) {
        symbolKey.forEach(key => params.append("symbolKey", key));
      } else {
        params.append("symbolKey", symbolKey);
      }
    }

    const res = await axios.get("/api/user/metamask-verify-ownership", {
      headers: {
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: "0",
        "Content-Type": "application/json",
      },
      params,
    });

    return res.data;
  } catch (err) {
    console.error("Failed to fetch NFT status:", err);
    return { isOwner: false }; // Return a default value on error
  }
};

const verifyOwnership = async ({
  address,
  token,
  symbolKey,
}: {
  token?: string;
  address?: string;
  symbolKey?: string | string[];
}): Promise<OwnershipResult> => {
  if (!token && !address) {
    console.error("No token or address provided");
    return {
      isOwner: false,
    };
  }

  if (token) {
    return getNFTOwnership({ token, symbolKey });
  }

  if (address) {
    return getNFTOwnershipForMetamask({ address, symbolKey });
  }

  return {
    isOwner: false,
  }; // Fallback return type in case none of the conditions match
};

export default verifyOwnership;
