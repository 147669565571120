import { Popover } from "@headlessui/react";
import { useRouter } from "next/router";
import React from "react";
import Sheet, { SheetRef } from "react-modal-sheet";
import { usePopper } from "react-popper";

import { clsMerge } from "@/utils/cls-merge";
import { CancelIcon } from "@/utils/icon";

interface Props {
  disabled: boolean;
  popoverConfig: {
    placement: "left" | "right" | "left-start";
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleOnClick: (...args: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleClose: (...args: any) => void;
  buttonClassName?: string;
  content: React.ReactNode;
}

const ArtifactPopoverButton: React.FC<Props> = ({
  handleOnClick,
  handleClose,
  disabled,
  popoverConfig,
  buttonClassName,
  content,
}) => {
  const closeButtonRef = React.useRef<HTMLButtonElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const popoverRef = React.useRef<any>(null);
  const bottomSheetRef = React.useRef<SheetRef>(null);
  const router = useRouter();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [referenceElement, setReferenceElement] = React.useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [popperElement, setPopperElement] = React.useState<any>();

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: popoverConfig.placement,
    modifiers: [
      {
        name: "eventListeners",
        options: { scroll: false },
      },
    ],
  });

  return (
    <>
      <Popover className="hidden outline-none xl:grid" ref={popoverRef}>
        {({ open }) => (
          <>
            <Popover.Button
              className={buttonClassName}
              disabled={disabled}
              ref={setReferenceElement}
              onClick={() => {
                if (open) {
                  handleClose();
                } else {
                  handleOnClick();
                }
                router.push("/#explore", undefined, { scroll: true });
              }}
            />
            <Popover.Panel ref={setPopperElement} style={styles.popper} {...attributes.popper}>
              <div
                className={clsMerge(
                  "flex animate-fade-in-short items-center pl-4",
                  popoverConfig.placement == "left" && "pr-4",
                  popoverConfig.placement == "left-start" && "pr-4",
                )}
              >
                <div
                  className={clsMerge(
                    "h-0 w-0 border-b-[15px] border-r-[20px] border-t-[15px] border-b-transparent border-r-white border-t-transparent",
                    popoverConfig.placement == "left" && "order-2 rotate-180",
                    popoverConfig.placement == "left-start" && "order-2 mt-8 rotate-180 self-start",
                  )}
                />
                <div className="min-w-[600px] max-w-[300px] rounded-lg bg-white pb-[31px] pl-[22px] pr-[33px] pt-[27px]">
                  {content}
                </div>
                <Popover.Button
                  ref={closeButtonRef}
                  type="button"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <CancelIcon
                    className={clsMerge(
                      "absolute top-0 z-40 mx-3 my-3 h-6 w-6 text-grey-800 hover:text-grey-600",
                      popoverConfig.placement == "left" ? "right-8 " : "right-8",
                      popoverConfig.placement == "left-start" ? "right-8 " : "right-8",
                    )}
                  />
                </Popover.Button>
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>
      <div className="grid xl:hidden">
        <button
          className={buttonClassName}
          onClick={() => {
            router.push("/#explore");
            setIsOpen(true);
          }}
        />
        <Sheet
          initialSnap={1}
          isOpen={isOpen}
          ref={bottomSheetRef}
          snapPoints={[500, 500, 0]}
          onClose={() => {
            setIsOpen(false);
            handleClose();
          }}
        >
          <Sheet.Container>
            <Sheet.Header />
            <Sheet.Content>{content}</Sheet.Content>
          </Sheet.Container>

          <Sheet.Backdrop
            onTap={() => {
              setIsOpen(false);
              handleClose();
            }}
          />
        </Sheet>
      </div>
    </>
  );
};

export default ArtifactPopoverButton;
