/* eslint-disable react/no-multi-comp */

import AlexImg from "@public/alex_4x.png";
import DaveGallo from "@public/dave_gallo_4x.png";
import Nargeolet from "@public/nargeolet_4x.png";
import Tomasina from "@public/tomasina_4x.png";
import Image from "next/image";
import React from "react";

import AppPageTitle from "@/components/AppPageTitle";
import Container from "@/components/Layout/Container";
import { clsMerge } from "@/utils/cls-merge";
import { EBGaramond } from "@/utils/font";

const titanicCouncilWorks = [
  {
    name: "David Gallo, Ph.D.",
    position: "Oceanographer and Explorer",
    Description: () => {
      return (
        <>
          For more than 40 years, Dave has been at the forefront of ocean exploration, participating
          in and communicating the excitement of new technologies and scientific discoveries that
          shape our view of planet earth.
        </>
      );
    },
    imageSrc: DaveGallo,
  },
  {
    name: "Tomasina Ray",
    position: "Director of Collections",
    Description: () => {
      return (
        <>
          Tomasina started her career as the Exhibitions Registrar at the High Museum of Art,
          Atlanta, and as a Creative Project Manager. She is now in charge of overseeing the TITANIC
          collection, ensuring educational access, and maintaining it for future generations.
        </>
      );
    },
    imageSrc: Tomasina,
  },
  {
    name: "P.H. Nargeolet",
    inLovingMemory: true,
    position: "Director of Underwater Research",
    Description: () => {
      return (
        <>
          P.H. served as the Director of Underwater Research for E/M Group and RMS Titanic, Inc. and
          had an impressive and tenured history with TITANIC. He was widely considered the leading
          authority on the wreck site.
        </>
      );
    },
    imageSrc: Nargeolet,
  },
  {
    name: "Alexandra Klingelhofer",
    position: "Conservator of Record",
    Description: () => {
      return (
        <>
          Alex is a nationally recognized museum professional with a rare combination of curatorial
          and conservation experience.
        </>
      );
    },
    imageSrc: AlexImg,
  },
];

const Experts: React.FunctionComponent = () => {
  return (
    <Container className="relative mx-auto flex flex-col">
      <div
        className={clsMerge("mx-auto w-full", "space-y-[24px] md:space-y-[32px] lg:space-y-[60px]")}
      >
        <div className="text-center text-white">
          <AppPageTitle>Experts</AppPageTitle>
          <div className={"theme-body1 mt-6 font-normal"}>
            Meet the RMS Titanic, Inc. expert team who are responsible for the care and legacy of
            the TITANIC.
          </div>
        </div>
        <div
          className={clsMerge(
            "mx-auto flex flex-wrap justify-center gap-x-[17px] gap-y-[17px]",
            EBGaramond.className,
          )}
        >
          {titanicCouncilWorks.map(
            ({ imageSrc, inLovingMemory, name, position, Description }, index) => (
              <div
                className="relative flex h-[645px] max-h-[645px] min-h-[645px] w-[282px] min-w-[282px] max-w-[282px] flex-col place-content-end text-white md:col-span-1"
                key={`titanic-counncil-works-${index}`}
              >
                <Image
                  alt=""
                  className="absolute top-0 h-[282px] max-h-[282px] object-cover object-top"
                  src={imageSrc}
                />
                <div className="relative flex h-[363px] max-h-[363px] min-h-[363px] flex-col bg-[#191919] px-[16px] pt-[16px]">
                  <h4 className="theme-h4 uppercase text-titanic-gold-500">{name}</h4>
                  {inLovingMemory && (
                    <div className="text-sm font-semibold leading-normal text-titanic-gold-500">
                      (in loving memory)
                    </div>
                  )}
                  <div className="theme-small-text1">{position}</div>
                  <div className="theme-body2 mt-6 font-normal">
                    <Description />
                  </div>
                </div>
              </div>
            ),
          )}
        </div>
      </div>
    </Container>
  );
};

export default Experts;
