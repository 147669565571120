import { useAuth0 } from "@auth0/auth0-react";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import YouTube, { YouTubeProps } from "react-youtube";

import AppButton from "@/components/Button/AppButton";
import Container from "@/components/Layout/Container";
import useMixAuthenticated from "@/hooks/useMixAuthenticated";
import { clsMerge } from "@/utils/cls-merge";
import verifyOwnership from "@/utils/fetcher/verify-ownership";
import { EBGaramond } from "@/utils/font";

const onPlayerReady: YouTubeProps["onReady"] = event => {
  event.target.mute(); // Mute the video
};

const Expedition: React.FunctionComponent = () => {
  const { isAuthenticated, isWeb3Connected, address, user } = useMixAuthenticated();
  const { getAccessTokenSilently } = useAuth0();
  const isLogin = useMemo(
    () => isAuthenticated || isWeb3Connected,
    [isAuthenticated, isWeb3Connected],
  );

  const router = useRouter();

  const [isNftOwner, setIsNftOwner] = useState(false);

  useEffect(() => {
    (async () => {
      if (isLogin) {
        if (isWeb3Connected && address) {
          const { isOwner, address: verifyAddress } = await verifyOwnership({
            symbolKey: "TDJC1_0",
            address: address as string,
          });
          setIsNftOwner(isOwner && verifyAddress === address?.toLowerCase());
        } else if (user) {
          const token = await getAccessTokenSilently();
          const { isOwner, address: verifyAddress } = await verifyOwnership({
            symbolKey: "TDJC1_0",
            token,
          });
          setIsNftOwner(isOwner && verifyAddress === user?.email);
        }
      } else {
        setIsNftOwner(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWeb3Connected, address, user, isLogin]);

  const opts: YouTubeProps["opts"] = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      controls: 1,
      disablekb: 1,
      loop: 1,
      iv_load_policy: 3,
    },
  };
  return (
    <Container className="relative mx-auto flex flex-col items-start p-[40px] text-white">
      <h2
        className={clsMerge(
          "theme-h3 md:text-4xl md:font-semibold md:leading-normal",
          //   "w-full text-center text-[36px] font-[600] leading-[54px]",
          "w-full text-center",
          EBGaramond.className,
        )}
      >
        Expedition 2024
      </h2>
      <div className="theme-body2 md:theme-body1 mt-6 text-start font-normal">
        Join the digital journey for exclusive access to Titanic Expedition 2024. This mission,
        launched in July 2024, brings together top experts to explore the Titanic wreck site with
        cutting-edge technology and ROVs. Experience and engage with the Expedition like never
        before through the TITANIC&apos;s Digital Journey.
      </div>

      <div className="mt-10 flex w-full flex-col items-center justify-center gap-4 xl:flex-row">
        <div className="w-full md:flex-1">
          <YouTube
            className="h-full w-full"
            // iframeClassName="absolute w-full h-full"
            opts={opts}
            videoId="tSAuFIgLQMw"
            onReady={onPlayerReady}
          />
        </div>

        <div className="w-full md:flex-1">
          <YouTube
            className="h-full w-full"
            // iframeClassName="absolute w-full h-full"
            opts={opts}
            videoId="IOqwMqpxLP4"
            onReady={onPlayerReady}
          />
        </div>
      </div>

      <div className="z-20 flex w-full items-center justify-center">
        {isNftOwner && (
          <AppButton
            className="mx-6 my-10 w-full xs:w-auto lg:mx-0"
            size="large"
            variant="outlined"
            onClick={() => {
              router.push("/expedition");
            }}
          >
            LEARN MORE
          </AppButton>
        )}
      </div>
    </Container>
  );
};

export default Expedition;
