import { HTMLAttributes, forwardRef } from "react";

import { clsMerge } from "@/utils/cls-merge";
import { EBGaramond } from "@/utils/font";

type AppPageProps = HTMLAttributes<HTMLDivElement>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AppPageTitle = forwardRef(({ children, className, ...rest }: AppPageProps, ref: any) => {
  return (
    <h1
      className={clsMerge(
        "theme-h3 font-semibold text-white md:text-4xl md:leading-[54px]",
        EBGaramond.className,
        className,
      )}
      ref={ref}
      {...rest}
    >
      {children}
    </h1>
  );
});

AppPageTitle.displayName = "AppPageTitle";

export type { AppPageProps };
export default AppPageTitle;
