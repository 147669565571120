import { HTMLAttributes, forwardRef } from "react";

import { clsMerge } from "@/utils/cls-merge";
import { inter } from "@/utils/font";

type AppPageProps = HTMLAttributes<HTMLDivElement>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AppPageSubTitle = forwardRef(({ children, className, ...rest }: AppPageProps, ref: any) => {
  return (
    <h3
      className={clsMerge(
        "text-body1 font-semibold text-titanic-blue md:text-xl md:leading-normal",
        inter.className,
        className,
      )}
      ref={ref}
      {...rest}
    >
      {children}
    </h3>
  );
});

AppPageSubTitle.displayName = "AppPageSubTitle";

export type { AppPageProps };
export default AppPageSubTitle;
