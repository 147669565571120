import React from "react";

import { clsMerge } from "@/utils/cls-merge";
import { inter } from "@/utils/font";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  extraClassNames?: string;
}

const ComingSoon: React.FC<Props> = ({ className, ...rest }) => {
  return (
    <div
      className={clsMerge(
        "flex items-center justify-center rounded-[100px] antialiased",
        "bg-titanic-gold-500 text-xs font-normal leading-[18px] text-white",
        "h-[24px] w-[91px]",
        inter.className,
        className,
      )}
      {...rest}
    >
      Coming soon
    </div>
  );
};

export default ComingSoon;
