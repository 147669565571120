/* eslint-disable react/no-unescaped-entities */
import ArtifactLabsLogo from "@public/artifactlabs_logo_4x.png";
import TitanicLogo from "@public/rms_titanic_logo_2x.png";
import Image from "next/image";
import Link from "next/link";
import React from "react";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/AppAccordion";
import AppPageTitle from "@/components/AppPageTitle";
import Container from "@/components/Layout/Container";
import { clsMerge } from "@/utils/cls-merge";
import { EBGaramond, inter } from "@/utils/font";

const faqs = [
  {
    question: "Who is behind this project?",
    answer: (
      <div className="text-base leading-7 text-white">
        <div className="flex items-center justify-start">
          <Link href="https://www.discovertitanic.com/" rel="noopener noreferrer" target="_blank">
            <Image
              alt="titanic logo"
              className="h-[43px] w-[97px] xl:h-[69px] xl:w-[155px]"
              src={TitanicLogo}
              unoptimized
            />
          </Link>
        </div>
        <div className="mt-4">
          <Link
            className="text-titanic-blue-500 underline decoration-titanic-blue-500 underline-offset-2"
            href="https://www.discovertitanic.com/about-us/"
          >
            RMS Titanic, Inc.
          </Link>{" "}
          is an affiliate of Experiential Media Group 'EMG', LLC (“E/M Group”). The Company was
          granted salvage rights to the wreck of the TITANIC by a United States Federal Court order
          in 1994 and reconfirmed again in 1996. The court award includes the exclusive rights to
          recover the artifacts from the wreck site. Using TITANIC's artifacts in concert with
          scientific data and social history, RMS Titanic, Inc. brings to life TITANIC: The Artifact
          Exhibition, and TITANIC's Digital Journey.
        </div>
        <div className="mt-4">
          In April, 2022, RMS Titanic, Inc. partnered with Crypto.com to celebrate the 110th
          Anniversary of the maiden voyage.{" "}
          <Link
            className="text-titanic-blue-500 underline decoration-titanic-blue-500 underline-offset-2"
            href="https://www.discovertitanic.com/anniversary-nft/"
          >
            Learn more
          </Link>{" "}
          about this collection.
        </div>
        <div className="mt-10 flex items-center justify-start">
          <Link href="https://www.artifactlabs.com/" rel="noopener noreferrer" target="_blank">
            <Image
              alt="artifactlabs logo"
              className="h-[40px] w-[38px] xl:h-[64px] xl:w-[61px]"
              src={ArtifactLabsLogo}
              unoptimized
            />
          </Link>
        </div>
        <div className="mt-4">
          <Link
            className="text-titanic-blue-500 underline decoration-titanic-blue-500 underline-offset-2"
            href="https://www.artifactlabs.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Artifact Labs
          </Link>{" "}
          is a blockchain company serving the world's leading museums, art & culture practitioners,
          and technologists to maximise the potential of their content and collections. Artifact
          Labs supports the preservation of arts and cultural legacies through safeguarding
          collections, with an aim to maximise the potential of arts and cultural content and make
          it accessible to the public in innovative ways.
        </div>
      </div>
    ),
  },
  {
    question: "What is this project about?",
    answer: (
      <div className="text-base leading-7 text-white">
        <div>
          TITANIC's Digital Journey is an effort to identify and establish a group of enthusiastic
          “Explorers” who want to gain unparalleled and exclusive access to TITANIC's history,
          engage with the community, and contribute to TITANIC's ongoing narrative and legacy.
        </div>
        <div className="mt-4">
          Purchase a membership card, become an Explorer, and delve into a unique exploration of its
          history and legacy. On this transformative journey, where history meets innovation, gain
          exclusive access to TITANIC's captivating history, artifacts, in-depth research, and
          ongoing recovery efforts.
        </div>
      </div>
    ),
  },
  {
    question: "What is a membership card?",
    answer: (
      <div className="text-base leading-7 text-white">
        <div>
          The Chapter 1 membership card is your passport to the forefront of digital exploration
          with TITANIC's Digital Journey, focusing on the monumental task of reuniting the Big Piece
          and the Little Big Piece. This exclusive membership not only signifies your role as an
          Explorer in this unique endeavor but also integrates you into a community dedicated to
          preserving and celebrating TITANIC's legacy.
        </div>
        <div className="mt-4">
          The membership cards are minted on the Polygon blockchain as non-fungible tokens (NFTs).
          The membership is non-tradable.
        </div>
      </div>
    ),
  },
  {
    question: "Are there different levels of membership?",
    answer: <div className="text-base leading-7 text-white">No.</div>,
  },
  {
    question: "Does the onetime payment include all chapters?",
    answer: (
      <div className="text-base leading-7 text-white">
        Membership includes all benefits for Chapter 1 and Chapter 2. New experiences may be
        introduced in the future that may be part of basic membership.
      </div>
    ),
  },
  {
    question: "What are the benefits of the membership?",
    answer: (
      <div className="text-base leading-7 text-white">
        <div>
          As an Explorer with the Chapter 1 membership card NFT, you're entitled to the following
          exclusive{" "}
          <span>
            <Link
              className="text-titanic-blue-500 underline decoration-titanic-blue-500 underline-offset-2"
              href="/benefits"
            >
              membership benefits
            </Link>
          </span>
          :
        </div>
        <ul className="mt-4 list-inside list-disc">
          <li>Access to Expedition 2024</li>
          <li>Access to the TITANIC Speakers Series</li>
          <li>Priority Access to Special Drops</li>
          <li>Recognition on the Community Wall</li>
          <li>Discount at the Official TITANIC Stores</li>
          <li>Chapter 1 Exclusive Merchandise</li>
        </ul>
      </div>
    ),
  },
  {
    question:
      "Do you have to do more than purchase a chapter to remain a member? Does membership expire / have to be renewed?",
    answer: (
      <div className="text-base leading-7 text-white">
        Membership is one time and does not expire or require renewal.
      </div>
    ),
  },
  {
    question: "What do I need to get started?",
    answer: (
      <div className="text-base leading-7 text-white">
        <div>
          All you need to begin your journey is an email address and a credit card. Our payment
          experience is powered by{" "}
          <span>
            <Link
              className="text-titanic-blue-500 underline decoration-titanic-blue-500 underline-offset-2"
              href="https://stripe.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              Stripe
            </Link>
          </span>
          &nbsp;and supports most global credit cards for online payment.
        </div>
        <div className="mt-4">
          For users who'd like to use their own Web3 wallets, we support log-in via Metamask and
          payment in USDC.
        </div>
      </div>
    ),
  },
  {
    question: "Can I sell/transfer my membership card NFT?",
    answer: (
      <div className="text-base leading-7 text-white">
        The membership card NFT is non-transferable, and members cannot resell the NFT after the
        purchase.
      </div>
    ),
  },
];

const FAQs: React.FunctionComponent = () => {
  return (
    <div className={clsMerge("bg-[##191919] text-white", EBGaramond.className)}>
      <Container className="mx-auto">
        <div className="mx-auto max-w-[700px]">
          <AppPageTitle className="pb-10 text-center">FAQs</AppPageTitle>
          <div className={clsMerge(inter.className, "border-y-[0.5px] border-[#FFFFFF66]")}>
            <Accordion type="single" collapsible>
              {faqs.map(({ question, answer }, index) => (
                <AccordionItem key={index} value={`item-${index}`}>
                  <AccordionTrigger
                    className={clsMerge(
                      "flex w-full cursor-pointer items-center justify-between py-6 text-left",
                    )}
                  >
                    <span className="theme-body1 md:theme-h3 font-normal">{question}</span>
                  </AccordionTrigger>
                  <AccordionContent
                    className={clsMerge(
                      "theme-body2 md:theme-body1 border-b-[1px] border-[#FFFFFF66] py-[24px]  font-normal font-normal",
                    )}
                  >
                    {answer}
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
          {/* divider */}
          <div className="mt-[28px]" />
        </div>
        <div />
      </Container>
    </div>
  );
};

export default FAQs;
