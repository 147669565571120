import { random } from "lodash";
import Image from "next/image";
import ArtifactCh101 from "public/home/artifact-ch2-01.png";
import ArtifactCh102 from "public/home/artifact-ch2-02.png";
import ArtifactCh103 from "public/home/artifact-ch2-03.png";
import Artifact01 from "public/home/artifact01.png";
import Artifact02 from "public/home/artifact02.png";
import Artifact03 from "public/home/artifact03.png";
import Artifact04 from "public/home/artifact04.png";
import Artifact05 from "public/home/artifact05.png";
import Artifact06 from "public/home/artifact06.png";
import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import AppPageSubTitle from "@/components/AppPageSubtitle";
import AppPageTitle from "@/components/AppPageTitle";
import ComingSoon from "@/components/ComingSoon";
import ExpeditionCarousel from "@/components/ExpeditionCarousel";
import Container from "@/components/Layout/Container";
import { clsMerge } from "@/utils/cls-merge";
import { EBGaramond } from "@/utils/font";

const Journey: React.FunctionComponent = () => {
  const bubbleElements = Array.from({ length: 110 });

  const renderBubbleElements = () => {
    return bubbleElements.map((_, index) => {
      const bottom = random(0, 80) + "%";
      const left = random(-2, 102) + "%";
      const size = random(10, 30) + "px";
      const animationDelay = random(0, 3) + "s";
      const animationDuration = random(7, 15) + "s";

      return (
        <li
          key={index}
          style={{
            insetBlockEnd: bottom,
            insetInlineStart: left,
            inlineSize: size,
            blockSize: size,
            animationDelay,
            animationDuration,
            backgroundColor: "radial-gradient(#6dd5ed40, #84b9bf30, #78a4bf65)",
          }}
        />
      );
    });
  };

  return (
    <Container
      className={clsMerge(
        "relative mx-auto flex flex-col items-start px-[15px] md:px-[112px]",
        EBGaramond.className,
      )}
    >
      <div className="z-20 w-full pb-20">
        <ExpeditionCarousel />
      </div>
      <ul className="bg-bubbles">{renderBubbleElements()}</ul>
      <ol className="relative w-full border-s border-titanic-blue text-white">
        <div className="absolute -left-[5.5px] -top-[4.5px] h-0 w-0 border-x-[5px] border-b-[7px] border-solid border-titanic-blue-500 border-x-transparent" />
        <div className="absolute -bottom-[4.5px] -left-[5.5px] h-0 w-0 rotate-180 border-x-[5px] border-b-[7px] border-solid border-titanic-blue-500 border-x-transparent" />

        <div className="ms-6">
          <h2 className="theme-h3 md:text-4xl md:font-semibold md:leading-normal">Overview</h2>
          <div className="theme-body2 md:theme-body1 mt-6 font-normal">
            Join TITANIC&apos;s Digital Journey as an Explorer and delve into a unique exploration
            of its history and legacy. On this transformative journey, where history meets
            innovation, gain exclusive access to TITANIC&apos;s captivating history, artifacts,
            in-depth research, and ongoing recovery efforts.
          </div>
          <div className="theme-body2 md:theme-body1 mt-6 font-normal font-normal">
            The journey is powered by blockchain, to ensure TITANIC&apos;s artifacts are preserved
            immutably and that we build a transparent and strong community.{" "}
          </div>
        </div>
        <li className="ms-6 mt-20">
          <div className="absolute -start-1.5 mt-1.5 h-3 w-3 rounded-full bg-titanic-blue" />
          <AppPageSubTitle>Chapter 1: Experience</AppPageSubTitle>
          <AppPageTitle>Reuniting the Big and the Little Big Piece</AppPageTitle>
          <div className="md:theme-body1 theme-body2 mt-6 space-y-6 font-normal font-normal md:space-y-7">
            <div>
              Embark on the inaugural chapter of TITANIC&apos;s Digital Journey and connect with
              history like never before.
            </div>
            <div>
              This chapter is centered around two iconic artifacts: the Big Piece and the Little Big
              Piece. These remarkable fragments, each with its own story and significance,
              originally formed a part of TITANIC&apos;s hull. Measuring 312.25 x 272 x 56 in, their
              journey from the ocean&apos;s depths to recovery split them into two.
            </div>
            <div>
              In Chapter 1, you, as an Explorer, are invited to reunite these pieces virtually,
              marking a collective effort to piece together TITANIC&apos;s story. Join a community
              of like-minded individuals in this groundbreaking initiative to merge history and
              digital innovation.
            </div>
          </div>
          <div className="mx-auto w-full pt-10 xl:w-[996px]">
            <ResponsiveMasonry className="w-full" columnsCountBreakPoints={{ 350: 2, 1280: 3 }}>
              <Masonry gutter={"16px"}>
                <Image alt="" className="xl:h-[216px] xl:w-[216px] xl:self-end" src={Artifact01} />
                <Image alt="" className="xl:h-[325px] xl:w-[325px]" src={Artifact02} />
                <Image alt="" className="xl:h-[258px] xl:w-[258px]" src={Artifact03} />
                <Image alt="" className="xl:h-[325px] xl:w-[325px]" src={Artifact04} />
                <Image alt="" className="xl:h-[350px] xl:w-[350px]" src={Artifact05} />
                <Image alt="" className="xl:h-[195px] xl:w-[195px]" src={Artifact06} />
              </Masonry>
            </ResponsiveMasonry>
          </div>
          <div className="theme-body2 md:theme-body1 mt-10 font-normal font-normal md:mt-20">
            Stay tuned for the upcoming chapters of TITANIC&apos;s Digital Journey. Your
            participation in Chapter 1 grants you priority access to future adventures. Experience,
            discover, and connect with TITANIC&apos;s legacy and be a part of the journey as an
            Explorer yourself.
          </div>
        </li>
        <li className="ms-6 mt-20">
          <div className="absolute -start-1.5 mt-1.5 h-3 w-3 rounded-full bg-titanic-blue" />
          <AppPageSubTitle className="flex items-center gap-4">
            Chapter 2: Discover
            <span>
              <ComingSoon />
            </span>
          </AppPageSubTitle>
          <AppPageTitle>“Discover” the Depth of the Wreck Site</AppPageTitle>
          <div className="theme-body2 md:theme-body1 mt-6 space-y-7 font-normal font-normal">
            <div>
              Participate in the Discovery mission and help determine the expedition route and the
              fate of the artifacts.
            </div>
            <div className="mx-auto w-[321px] pt-10  lg:w-[724px]">
              <ResponsiveMasonry className="w-full" columnsCountBreakPoints={{ 350: 2 }}>
                <Masonry gutter={"16px"}>
                  <Image
                    alt=""
                    className="lg:h-[275px] lg:w-[275px] lg:self-end"
                    src={ArtifactCh101}
                  />
                  <Image alt="" className="lg:h-[320px] lg:w-[125px]" src={ArtifactCh103} />
                  <Image
                    alt=""
                    className="lg:h-[125px] lg:w-[220px] lg:self-end"
                    src={ArtifactCh102}
                  />
                </Masonry>
              </ResponsiveMasonry>
            </div>
          </div>
        </li>
        <li className="ms-6 mt-20 md:mt-[120px]">
          <div className="absolute -start-1.5 mt-1.5 h-3 w-3 rounded-full bg-titanic-blue" />
          <AppPageSubTitle className="flex items-center gap-4">
            Chapter 3: Connect
            <span>
              <div
                className={clsMerge(
                  "flex items-center justify-center rounded-[100px] antialiased",
                  "theme-small-text2 border border-titanic-blue-500 text-titanic-blue-500",
                  "h-[24px] px-2",
                )}
              >
                2025
              </div>
            </span>
          </AppPageSubTitle>
          <AppPageTitle>Own a Piece of TITANIC</AppPageTitle>
          <div className="theme-body2 md:theme-body1 mt-6 space-y-7 font-normal font-normal">
            <div>
              Own an authenticated physical piece of TITANIC, brought to you by RMS Titanic, Inc.
            </div>
          </div>
        </li>
        <li className="ms-6 mt-20 md:mt-[120px]">
          <div className="absolute -start-1.5 mt-1.5 h-3 w-3 rounded-full bg-titanic-blue" />
          <AppPageSubTitle className="flex items-center gap-4">
            Chapter 4: Move
            <span>
              <div
                className={clsMerge(
                  "flex items-center justify-center rounded-[100px] antialiased",
                  "theme-small-text2 border border-titanic-blue-500 text-titanic-blue-500",
                  "h-[24px] px-2",
                )}
              >
                2025
              </div>
            </span>
          </AppPageSubTitle>
          <AppPageTitle>Recover Artifacts from -12,500 ft</AppPageTitle>
          <div className="theme-body2 md:theme-body1 mt-6 space-y-7 font-normal font-normal">
            <div>
              Navigate through the deep ocean and witness the recovery of TITANIC&apos;s artifacts
              from 12,500 feet below the surface.
            </div>
          </div>
        </li>
      </ol>
    </Container>
  );
};

export default Journey;
