import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useInView } from "framer-motion";
import { DateTime } from "luxon";
import React, { HTMLAttributes, useEffect, useMemo, useRef, useState } from "react";
import { useCountUp } from "use-count-up";

import CommunityBlock from "./CommunityBlock";

import AppPageTitle from "@/components/AppPageTitle";
import Container from "@/components/Layout/Container";
import useMixAuthenticated from "@/hooks/useMixAuthenticated";
import { clsMerge } from "@/utils/cls-merge";
import { EBGaramond } from "@/utils/font";

const getTransactions = async (address?: string, token?: string) => {
  let params = {
    pageSize: 50,
  };
  if (address) {
    params = Object.assign(params, { address });
  }
  const res = await axios.get("/api/assets/wall", {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
    params,
  });
  return res.data;
};

interface CommunityWallProps extends HTMLAttributes<HTMLDivElement> {
  withDescriptions?: boolean;
}

const CommunityWall: React.FunctionComponent<CommunityWallProps> = ({
  withDescriptions = true,
  className,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const { isAuthenticated, isWeb3Connected, address, user } = useMixAuthenticated();
  const isLogin = useMemo(
    () => isAuthenticated || isWeb3Connected,
    [isAuthenticated, isWeb3Connected],
  );
  const blockRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [myLatestTransaction, setMyLatestTransaction] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [transactions, setTransactions] = useState([]);
  const rNumYearOfHistory = useRef(null);
  const rNumVisitorsPerYear = useRef(null);
  const rNumCommunities = useRef(null);
  const rNumArtifactRecovered = useRef(null);

  const isNumYearOfHistoryInView = useInView(rNumYearOfHistory);
  const isNumVisitorsPerYearInView = useInView(rNumVisitorsPerYear);
  const isNumCommunitiesInView = useInView(rNumCommunities);
  const isNumArtifactRecovered = useInView(rNumArtifactRecovered);

  useEffect(() => {
    const updateTransactionsAndSetInterval = async () => {
      await updateTransactions();
    };

    updateTransactionsAndSetInterval();

    const intervalId = setInterval(updateTransactionsAndSetInterval, 60 * 1000 * 2);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWeb3Connected, address, user, isLogin]);

  const updateTransactions = async () => {
    const txAddress = isWeb3Connected ? address : user?.email;
    setIsLoading(true);
    if (isLogin && txAddress) {
      if (isWeb3Connected) {
        const data = await getTransactions(txAddress);
        setTransactions(data.transactions);
        setMyLatestTransaction(data.latestUserTransaction);
      } else {
        const token = await getAccessTokenSilently();
        const data = await getTransactions(txAddress, token);
        setTransactions(data.transactions);
        setMyLatestTransaction(data.latestUserTransaction);
      }
    } else {
      const data = await getTransactions();
      setTransactions(data.transactions);
      setMyLatestTransaction(null);
    }
    setIsLoading(false);
  };

  const { value: numYearOfHistory } = useCountUp({
    isCounting: isNumYearOfHistoryInView,
    start: 0,
    end: 112,
    duration: 1,
    easing: "easeOutCubic",
    decimalPlaces: 0,
    thousandsSeparator: "",
    decimalSeparator: "",
  });

  const { value: numVisitorsPerYear } = useCountUp({
    isCounting: isNumVisitorsPerYearInView,
    start: 0,
    end: 30,
    duration: 1,
    easing: "easeOutCubic",
    decimalPlaces: 0,
    thousandsSeparator: ",",
    decimalSeparator: "",
  });

  const { value: numCommunities } = useCountUp({
    isCounting: isNumCommunitiesInView,
    start: 0,
    end: 100,
    duration: 1,
    easing: "easeOutCubic",
    decimalPlaces: 0,
    thousandsSeparator: "",
    decimalSeparator: "",
  });

  const { value: numArtifactRecovered } = useCountUp({
    isCounting: isNumArtifactRecovered,
    start: 0,
    end: 5500,
    duration: 1,
    easing: "easeOutCubic",
    decimalPlaces: 0,
    thousandsSeparator: ",",
    decimalSeparator: "",
  });

  return (
    <Container
      className={clsMerge(
        "relative flex w-full flex-col items-start justify-center px-[15px] pt-20 md:px-[112px]",
        EBGaramond.className,
        className,
      )}
    >
      <AppPageTitle className="md:mx-auto">Community Wall</AppPageTitle>
      {withDescriptions && (
        <div className="theme-body2 md:theme-body1 mx-auto mt-6 font-normal text-white md:text-center">
          Join the Explorer Community and see your name etched in digital history on our Community
          Wall - a symbol of unity and exploration among tens of thousands of TITANIC enthusiasts
          around the world. Here, every name tells a story of exploration and shared passion for
          TITANIC&apos;s ongoing history. Celebrate TITANIC&apos;s legacy with fellow explorers from
          around the world who are committed to preserving TITANIC&apos;s memories.
        </div>
      )}
      <div
        className={clsMerge(
          "mx-auto mt-20 grid h-full w-full max-w-[996px] grid-cols-2 grid-rows-4 gap-6 md:grid-cols-3 md:grid-rows-2",
        )}
      >
        <div className="col-span-1 row-span-1 flex aspect-[1/1] items-center justify-center bg-titanic-black/50 px-[15px] backdrop-blur-sm">
          <div
            className={clsMerge(
              "theme-h3 md:theme-h2 text-center text-titanic-gold",
              EBGaramond.className,
            )}
            ref={rNumVisitorsPerYear}
          >
            {numVisitorsPerYear}M+
            <div className="theme-body2 md:theme-body1 font-bold text-white">
              Visitors to the TITANIC Exhibitions
            </div>
          </div>
        </div>
        <div className="row-span-1 flex aspect-[1/1] items-center justify-center bg-titanic-black/50 px-[15px] backdrop-blur-sm first-letter:col-span-1">
          <div
            className={clsMerge(
              "theme-h3 md:theme-h2 text-center text-titanic-gold",
              EBGaramond.className,
            )}
            ref={rNumYearOfHistory}
          >
            {numYearOfHistory}
            <div className="theme-body2 md:theme-body1 font-semibold text-white">
              Years of History
            </div>
          </div>
        </div>
        <div className="col-span-1 row-span-1 flex aspect-[1/1] items-center justify-center bg-titanic-black/50 px-[15px] backdrop-blur-sm">
          <div
            className={clsMerge(
              "theme-h3 md:theme-h2 text-center text-titanic-gold",
              EBGaramond.className,
            )}
            ref={rNumCommunities}
          >
            {numCommunities}+
            <div className={clsMerge("theme-body2 md:theme-body1 font-semibold text-white")}>
              Communities Online & Offline
            </div>
          </div>
        </div>
        <div
          className="col-span-1 row-span-1 flex aspect-[1/1] items-center justify-center bg-titanic-black/50 px-[15px] backdrop-blur-sm"
          ref={blockRef}
        >
          <div
            className={clsMerge(
              "theme-h3 md:theme-h2 text-center text-titanic-gold",
              EBGaramond.className,
            )}
            ref={rNumArtifactRecovered}
          >
            {numArtifactRecovered}+
            <div className={clsMerge("theme-body2 md:theme-body1 font-semibold text-white")}>
              Artifacts Recovered from the Wreck Site
            </div>
          </div>
        </div>
        <div
          className="marquee relative  col-span-2 row-span-2 w-full overflow-hidden bg-titanic-black/50 backdrop-blur-sm md:col-start-3 md:col-end-4 md:row-start-1 md:row-end-3"
          style={{
            maxBlockSize: (blockRef.current?.clientHeight ?? 0) * 2 + 24, // two block plus margin
          }}
        >
          {!isLoading && (
            <>
              {myLatestTransaction && (
                <div className="z-10 flex min-h-[97px] flex-col justify-center border-b-[1px] border-b-[#191919] bg-titanic-black/50 px-6 backdrop-blur-xl">
                  <CommunityBlock
                    timestamp={
                      DateTime.fromISO(myLatestTransaction?.transactionTimestamp).toRelative() ?? ""
                    }
                    title={myLatestTransaction?.customName}
                  />
                </div>
              )}
              {transactions.length > 0 &&
                Array.from({ length: 2 }).map((_, index) => (
                  <div className="marquee-content marquee-scroll px-6" key={`marquee.${index}`}>
                    {
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      transactions?.map((each: any, i) => (
                        <CommunityBlock
                          key={`item.${i}`}
                          timestamp={
                            DateTime.fromISO(each?.transactionTimestamp).toRelative() ?? ""
                          }
                          title={each?.customName}
                        />
                      ))
                    }
                  </div>
                ))}
            </>
          )}
        </div>
      </div>
    </Container>
  );
};

export default CommunityWall;
