/* eslint-disable react/no-multi-comp */


import DavidDgltitanic from "@public/david_dgltitanic.png";
import JamesPenca from "@public/james_penca.png";
import KayleeJukichFish from "@public/kaylee_jukich-fish.png";
import SocialInstagram from "@public/social/Instagram.svg";
import SocialTikTokIcon from "@public/social/TikTok.svg";
import SocialWebIcon from "@public/social/Website.svg";
import SocialYoutubeIcon from "@public/social/YouTube.svg";
import TitanicGuyRafAvila from "@public/titanic_guy_raf_avila.png";
import Image from "next/image";
import Link from "next/link";
import React from "react";

import AppPageTitle from "@/components/AppPageTitle";
import Container from "@/components/Layout/Container";
import { clsMerge } from "@/utils/cls-merge";
import { EBGaramond } from "@/utils/font";

const titanicCouncilWorks = [
  {
    name: "JAMES PENCA",
    Description: () => {
      return (
        <>
          “The expedition video is top-quality content that is worth every penny. It is engaging and
          informative, keeping users hooked from start to finish.”
        </>
      );
    },
    imageSrc: JamesPenca,
    social: {
      web: "https://www.titanichg.com",
      youtube: "https://www.youtube.com/@WitnessTitanic",
    },
  },
  {
    name: "TITANIC GUY RAF AVILA",
    Description: () => {
      return (
        <>
          “This is why we love the Titanic. This innovative initiative is bringing all the
          communities together and feeding our curiosities and passion with never before seen
          footage.”
        </>
      );
    },
    imageSrc: TitanicGuyRafAvila,
    social: {
      web: "https://titanicguy.com",
      youtube: "https://www.youtube.com/@Raf_Avila",
      tikTok: "https://www.tiktok.com/@raf_avila",
    },
  },
  {
    name: "DAVID (DGLTITANIC)",
    Description: () => {
      return (
        <>
          “Es lo más parecido a estar en la propia expedición, así que me inscribí enseguida. El
          acceso y el contenido que obtenemos es increíble y hay más por venir.”
        </>
      );
    },
    imageSrc: DavidDgltitanic,
    social: {
      youtube: "https://www.youtube.com/@dglTitanic",
      instagram: "https://www.instagram.com/dgltitanic/",
    },
  },
  {
    name: "KAYLEE JUKICH-FISH",
    Description: () => {
      return (
        <>
          This is an exciting new initiative to bring TITANIC closer to us than ever before!
          I&apos;m excited about how we will be able to be a part of the TITANIC&apos;s legacy.
        </>
      );
    },
    imageSrc: KayleeJukichFish,
    social: {
      instagram: "https://www.instagram.com/kjfishh",
    },
  },
];

const Ecosystem: React.FunctionComponent = () => {
  return (
    <Container className="relative mx-auto flex flex-col">
      <div
        className={clsMerge("mx-auto w-full", "space-y-[24px] md:space-y-[32px] lg:space-y-[60px]")}
      >
        <div className="text-center text-white">
          <AppPageTitle>Ecosystem</AppPageTitle>
          <div className={"theme-body1 mx-auto mt-6 font-normal text-white md:text-center"}>
            Join our community of TITANIC enthusiasts driving the conversation and creating
            communities. Our Key Opinion Leaders (KOLs) and influencers are passionate about the
            TITANIC&apos;s legacy, sharing insights, discoveries, and stories. Engage with experts
            and fellow enthusiasts as we explore, preserve, and celebrate the rich history of the
            TITANIC together. Be a part of the conversation and contribute to this incredible
            journey.
          </div>
        </div>
        <div
          className={clsMerge(
            "mx-auto flex flex-wrap justify-center gap-x-[24px] gap-y-[24px]",
            EBGaramond.className,
          )}
        >
          {titanicCouncilWorks.map(({ imageSrc, name, social, Description }, index) => (
            <div
              className="relative flex h-[348px] max-h-[348px] w-[282px] min-w-[282px] max-w-[282px] flex-col place-content-end text-white md:col-span-1"
              key={`titanic-counncil-works-${index}`}
            >
              <div className="relative flex h-[348px] max-h-[348px] flex-col bg-[#191919] px-[24px] py-[24px]">
                <div className="flex items-center justify-start">
                  <Image
                    alt=""
                    className="h-[60px] w-[60px] cursor-pointer rounded-full"
                    src={imageSrc}
                  />
                  <h4 className="theme-h4 pl-[24px] uppercase text-titanic-gold-500">{name}</h4>
                </div>
                <div className={clsMerge("pt-[24px]", "theme-body2 font-normal italic")}>
                  <Description />
                </div>
                <div className="mt-auto flex pt-[24px]">
                  {social?.web && (
                    <div className="pr-[24px]">
                      <button className="h-[24px] w-[24px]">
                        <Link
                          className="flex items-center justify-start"
                          href={social.web}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <SocialWebIcon />
                        </Link>
                      </button>
                    </div>
                  )}
                  {social?.youtube && (
                    <div className="pr-[24px]">
                      <button className="h-[24px] w-[24px]">
                        <Link
                          className="flex items-center justify-start"
                          href={social.youtube}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <SocialYoutubeIcon />
                        </Link>
                      </button>
                    </div>
                  )}
                  {social?.tikTok && (
                    <div className="pr-[24px]">
                      <button className="h-[24px] w-[24px]">
                        <Link
                          className="flex items-center justify-start"
                          href={social.tikTok}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <SocialTikTokIcon />
                        </Link>
                      </button>
                    </div>
                  )}
                  {social?.instagram && (
                    <div className="pr-[24px]">
                      <button className="h-[24px] w-[24px]">
                        <Link
                          className="flex items-center justify-start"
                          href={social.instagram}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <SocialInstagram />
                        </Link>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default Ecosystem;
